import { scrollTo } from './scrollTo.js';



export function initScrollUpBtn() {

    let isIE = /*@cc_on!@*/false || !!document.documentMode

    // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.

    let button = document.createElement('a');
    button.setAttribute('href','#');
    button.setAttribute('class','no-btn back-to-top trigger-btn');
    /*button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm10 8a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm.7-10.54L14.25 11l-1.41 1.41L10 9.6l-2.83 2.8L5.76 11 10 6.76l.7.7z"/></svg>\
                                  <span class="invisible">Nach oben</span>'; */
    button.innerHTML = '<svg version="1.1" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"  >\
           <path d="m0.642059 12.5a11.8577 11.8577 0 1 1 11.8577 11.8577 11.8577 11.8577 0 0 1-11.8577-11.8577zm6.86552 1.38179 3.46173-3.60947v8.72722a1.14522 1.14522 0 0 0 1.14812 1.14908h0.764573a1.14522 1.14522 0 0 0 1.14812-1.14812v-8.72722l3.46173 3.60946a1.14908 1.14908 0 0 0 1.64058 0.01897l0.520756-0.525584a1.13846 1.13846 0 0 0 0-1.62127l-6.33926-6.34505a1.13846 1.13846 0 0 0-1.62127 0l-6.34891 6.34408a1.13846 1.13846 0 0 0 0 1.62127l0.520756 0.525584a1.15487 1.15487 0 0 0 1.64541-0.01897z"  stroke-width=".965614" data-name="Icon awesome-arrow-circle-right"/>\
    </svg>';
    document.getElementsByTagName('body')[0].appendChild(button);

    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      console.debug("intersection observer ");
      const observeable = document.createElement("span");
      observeable.id = "observeable";
      document.getElementById("header").parentNode.insertBefore(observeable,document.getElementById("header"));

      let observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < -1) {
          document.body.classList.add("not-top-anymore");
        } else {
          document.body.classList.remove("not-top-anymore");
        }
      });

      observer.observe(document.querySelector("#observeable"));
    } else {
      console.debug("event listener ");
      window.addEventListener('scroll',function(){
         if(window.scrollY > 100) {
           document.querySelector('.back-to-top').classList.add('fade-in');
           document.getElementsByTagName("body")[0].classList.add('not-top-anymore')
          } else {
            document.querySelector('.back-to-top').classList.remove('fade-in');
           document.getElementsByTagName("body")[0].classList.remove('not-top-anymore')
          }
      }, { passive: true });
    }

    /* if(!isIE) { */
      document.querySelector('.back-to-top').addEventListener('click',function(event){
          event.preventDefault();
          scrollTo(document.querySelector('html,body'),800,'easeInOutQuad');
      });
    /* } */
}


import baguetteBox  from './baguettebox.js';
import { scrollTo } from './scrollTo.js';
import { toggleClass } from './mobileNavigation.js';
import { initScrollUpBtn } from './scrollup.js';


let isIE = /*@cc_on!@*/false || !!document.documentMode


document.addEventListener("DOMContentLoaded", function(){
  'use strict';
  if(!isIE){
    //toggleClass('.nav-trigger.nav-open-trigger','.nav-open .nav-trigger.nav-close-trigger');
    toggleClass('#hamburger',' #nav-close');
  }
  // load ie11 css vars polyfill
  /* if(isIE) {
     let s = document.createElement('script');
     s.onload = function() {
       cssVars();
     }
     s.src = 'files/template/js/css-vars-ponyfill.min.js';
     document.getElementsByTagName('head')[0].appendChild(s);
  }*/

  /* baguetteBox.run('.ce_gallery, .ce_image a[data-lightbox], .ce_text a[data-lightbox] ', {
    animation: 'fadeIn',
    noScrollbars: false
  }); */

   /* document.querySelectorAll(".mainnav .level_1 > li.submenu > a,.mainnav .level_1 > li.submenu > strong").forEach(function(navelement){
    navelement.addEventListener('click',function(event) {
      event.preventDefault();
      let element = event.target.parentElement;



      //document.querySelectorAll(".mainnav .level_1 > li.navpoint-open ").forEach(function(navelement){
      //  navelement.classList.remove("navpoint-open");
      //});

      let closeElement = document.querySelector(".navpoint-open ");
      if(closeElement && closeElement != element) {
        closeElement.classList.remove("navpoint-open");
      }

      element.classList.toggle("navpoint-open");


    });
  });*/

  //ieHintEvents();
});




// weil js über script ganz unten und defer geladen wird
document.addEventListener("DOMContentLoaded",function(){
  'use strict';
   initScrollUpBtn();

});
